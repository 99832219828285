@import './site.scss';

section {
    padding: 2rem 8px;

    @include lg {
        padding: 80px 8px;
    }

    h2 {
        font-size: 3.5rem;
        font-family: $headline;
        line-height: 1;
        margin: 0 0 1rem;
    }
}

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 90vh;
    padding: 3rem 8px;
    min-height: 800px;
    width: 100%;

    .bg {
        @extend %fill-parent;

        display: flex;
        position: absolute;
        z-index: -1;

        &::after {
            @extend %fill-parent;
            content: '';
            background: rgba($dark-green, .92);
        }

        img {
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
        }
    }

    h1 {
        @include fade-enter(300ms);
        margin: 0 0 1.5rem;

        @include md {
            margin: 0 0 2rem;
        }

        img {
            height: auto;
            max-width: 100%;
            width: 700px
        }
    }

    p {
        @include fade-enter(1s);

        color: $chalk;
        font-family: $headline;
        font-size: 1.25rem;
        max-width: 30em;
        text-align: center;
        margin: 0 0 2.5rem;

        @include md {
            margin: 0 0 3rem;
        }

        @include md {
            font-size: 1.25rem;
        }

        strong {
            color: $light-green;

            &.bad {
                color: currentColor;
                text-decoration: underline;
                text-underline-offset: 5px;
                text-decoration-thickness: 4px;
                text-decoration-color: $red;
            }
        }

        em {
            font-family: $accent-font;
            font-style: normal;
            font-size: 1.75rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .btn {
        @include fade-enter(1s);

        background: $light-green;
        border-color: $light-green;
        box-shadow: 4px 4px 10px rgba(#fff, .1);
        color: $chalk;

        &:hover {
            background: $chalk;
            color: $light-green;
        }
    }
}

.lookup {
    background-color: $chalk;
    color: $dark-green;

    p {
        margin: 0;

        &.message {
            margin: 0 0 1rem;
            font-style: italic;
        }
    }

    .search {
        flex: 0 0 100%;
        margin: 0 auto;

        @include md {
            flex: 0 0 75%;
        }

        @include lg {
            flex: 0 0 50%;
        }
    }

    form {
        margin: 1rem auto;

        div {
            margin: .75rem 0;
            max-width: 100%;
            width: 400px;

            &.half {
                width: 200px;
            }

            label {
                display: block;
                color: $dark-green;
                font-size: 1.15rem;
                font-family: $headline;
                text-transform: uppercase;
                margin: 0;
            }

            input {
                appearance: none;
                border: 1.5px solid $dark-green;
                font-size: 1rem;
                font-family: $body;
                padding: 0 .5rem;
                width: 100%;
                height: 44px;
            }
        }

        button {
            margin-top: 1rem;
        }
    }

    .wrap {
        position: relative;

        &[aria-busy="true"] .loading {
            opacity: 1;
            visibility: visible;
        }

        .loading {
            background: rgba($dark-green, .5);
            box-shadow: 0 0 10px 10px rgba($dark-green, .5);
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: -.5rem;
            transition-property: opacity, visibility;
            transition-duration: 90ms;
            transition-timing-function: ease-in-out;
            left: -.5rem;
            right: -.5rem;
            bottom: -.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    h3 {
        font-family: $headline;
        font-size: 1.5rem;
        margin: 1.5rem 0 .5rem;
    }

    .candidates {
        border: 2px solid $dark-green;
        background: #fff;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
            align-items: center;
            display: flex;
            width: 100%;
            padding: .25rem .5rem;

            &:not(:last-child) {
                border-bottom: 1px dashed $dark-green;
            }

            &.neutral {
                opacity: .5;
            }

            &.support {
                color: $light-green;
                font-weight: 700;
            }

            &.oppose {
                color: $red;
                font-weight: 700;
            }

            img,
            .spacer {
                display: block;
                margin-right: .25rem;
                width: 1.25rem;
            }
        }
    }
}

.act {
    background-color: $light-green;
    color: #fff;

    .container {
        gap: 2rem;
        width: calc(100% - 2rem);
    }

    .col {
        flex: 0 0 100%;

            @include md {
            flex: 0 0 50%;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
        }

        &.vid {
            @include lg {
                order: -1;
            }
        }
    }

    .action {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include lg {
            flex-direction: row;
        }

        a {
            margin: 1rem 0;
            text-decoration: none;

            @include lg {
                margin: 0 1rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:not(.btn) {
                color: $chalk;
                font-family: $headline;
                font-size: 1.25rem;
                letter-spacing: .02em;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    iframe {
        aspect-ratio: 560/315;
        height: auto;
        width: 100%;
    }
}