@keyframes fade-enter {
  to {
    opacity: 1;
    transform: none;
  }
}

.hero .bg:after, .hero .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:nth-child(1) {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

* {
  box-sizing: border-box;
}

body, html {
  color: #06253fe5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: inter, sans-serif;
  font-size: 16px;
  line-height: 1.3333;
}

@media screen and (min-width: 800px) {
  body, html {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  body, html {
    font-size: 20px;
  }
}

body {
  overflow-x: hidden;
}

button, a.btn {
  appearance: none;
  cursor: pointer;
  color: #fff;
  min-height: 46px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .2em;
  background: #06253fe5;
  border: 2px solid #06253fe5;
  border-radius: 0;
  align-items: center;
  padding: .25rem 1.5rem;
  font-family: inter, sans-serif;
  font-size: 1.25rem;
  text-decoration: none;
  transition-property: background-color, border-color, color;
  transition-duration: .12s;
  transition-timing-function: ease;
  display: inline-flex;
  box-shadow: 4px 4px 10px #0000001a;
}

button:hover, a.btn:hover {
  color: #06253fe5;
  background-color: #fff;
}

.container {
  max-width: 1400px;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 800px) {
  .container {
    flex-wrap: nowrap;
  }
}

footer {
  color: #fff;
  text-align: center;
  background: #06253fe5;
  justify-content: center;
  padding: 40px 8px;
  position: relative;
}

footer div {
  width: 100%;
  margin: 20px auto;
  display: inline-block;
}

footer p {
  min-width: 150px;
  width: 20%;
  border: 1px solid;
  margin: 0 auto;
  padding: 1rem;
  font-size: 14px;
}

footer .footer-logo {
  height: .75rem;
  width: auto;
  display: inline;
}

section {
  padding: 2rem 8px;
}

@media screen and (min-width: 1200px) {
  section {
    padding: 80px 8px;
  }
}

section h2 {
  margin: 0 0 1rem;
  font-family: inter, sans-serif;
  font-size: 3.5rem;
  line-height: 1;
}

.hero {
  height: 90vh;
  min-height: 800px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 8px;
  display: flex;
  position: relative;
}

.hero .bg {
  z-index: -1;
  display: flex;
  position: absolute;
}

.hero .bg:after {
  content: "";
  background: #06253feb;
}

.hero .bg img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.hero h1 {
  margin: 0 0 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .hero h1 {
    opacity: 0;
    animation: .5s ease-out .3s forwards fade-enter;
    transform: translateY(1rem);
  }
}

@media screen and (min-width: 800px) {
  .hero h1 {
    margin: 0 0 2rem;
  }
}

.hero h1 img {
  height: auto;
  max-width: 100%;
  width: 700px;
}

.hero p {
  color: #fff;
  max-width: 30em;
  text-align: center;
  margin: 0 0 2.5rem;
  font-family: inter, sans-serif;
  font-size: 1.25rem;
}

@media (prefers-reduced-motion: no-preference) {
  .hero p {
    opacity: 0;
    animation: .5s ease-out 1s forwards fade-enter;
    transform: translateY(1rem);
  }
}

@media screen and (min-width: 800px) {
  .hero p {
    margin: 0 0 3rem;
    font-size: 1.25rem;
  }
}

.hero p strong {
  color: #2a72ff;
}

.hero p strong.bad {
  color: currentColor;
  text-underline-offset: 5px;
  -webkit-text-decoration: underline #a84a43;
  text-decoration: underline #a84a43;
  text-decoration-thickness: 4px;
}

.hero p em {
  text-transform: uppercase;
  font-family: inter, sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
}

.hero .btn {
  color: #fff;
  background: #2a72ff;
  border-color: #2a72ff;
  box-shadow: 4px 4px 10px #ffffff1a;
}

@media (prefers-reduced-motion: no-preference) {
  .hero .btn {
    opacity: 0;
    animation: .5s ease-out 1s forwards fade-enter;
    transform: translateY(1rem);
  }
}

.hero .btn:hover {
  color: #2a72ff;
  background: #fff;
}

.lookup {
  color: #06253fe5;
  background-color: #fff;
}

.lookup p {
  margin: 0;
}

.lookup p.message {
  margin: 0 0 1rem;
  font-style: italic;
}

.lookup .search {
  flex: 0 0 100%;
  margin: 0 auto;
}

@media screen and (min-width: 800px) {
  .lookup .search {
    flex: 0 0 75%;
  }
}

@media screen and (min-width: 1200px) {
  .lookup .search {
    flex: 0 0 50%;
  }
}

.lookup form {
  margin: 1rem auto;
}

.lookup form div {
  max-width: 100%;
  width: 400px;
  margin: .75rem 0;
}

.lookup form div.half {
  width: 200px;
}

.lookup form div label {
  color: #06253fe5;
  text-transform: uppercase;
  margin: 0;
  font-family: inter, sans-serif;
  font-size: 1.15rem;
  display: block;
}

.lookup form div input {
  appearance: none;
  width: 100%;
  height: 44px;
  border: 1.5px solid #06253fe5;
  padding: 0 .5rem;
  font-family: inter, sans-serif;
  font-size: 1rem;
}

.lookup form button {
  margin-top: 1rem;
}

.lookup .wrap {
  position: relative;
}

.lookup .wrap[aria-busy="true"] .loading {
  opacity: 1;
  visibility: visible;
}

.lookup .wrap .loading {
  opacity: 0;
  visibility: hidden;
  background: #06253f80;
  justify-content: center;
  align-items: center;
  transition-property: opacity, visibility;
  transition-duration: 90ms;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  inset: -.5rem;
  box-shadow: 0 0 10px 10px #06253f80;
}

.lookup h3 {
  margin: 1.5rem 0 .5rem;
  font-family: inter, sans-serif;
  font-size: 1.5rem;
}

.lookup .candidates {
  width: 100%;
  background: #fff;
  border: 2px solid #06253fe5;
  margin: 0;
  padding: 0;
  list-style: none;
}

.lookup .candidates li {
  width: 100%;
  align-items: center;
  padding: .25rem .5rem;
  display: flex;
}

.lookup .candidates li:not(:last-child) {
  border-bottom: 1px dashed #06253fe5;
}

.lookup .candidates li.neutral {
  opacity: .5;
}

.lookup .candidates li.support {
  color: #2a72ff;
  font-weight: 700;
}

.lookup .candidates li.oppose {
  color: #a84a43;
  font-weight: 700;
}

.lookup .candidates li img, .lookup .candidates li .spacer {
  width: 1.25rem;
  margin-right: .25rem;
  display: block;
}

.act {
  color: #fff;
  background-color: #2a72ff;
}

.act .container {
  width: calc(100% - 2rem);
  gap: 2rem;
}

.act .col {
  flex: 0 0 100%;
}

@media screen and (min-width: 800px) {
  .act .col {
    flex: 0 0 50%;
    margin: 0 auto;
  }
}

.act .col h2 {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .act .col.vid {
    order: -1;
  }
}

.act .action {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .act .action {
    flex-direction: row;
  }
}

.act .action a {
  margin: 1rem 0;
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  .act .action a {
    margin: 0 1rem;
  }
}

.act .action a:first-child {
  margin-left: 0;
}

.act .action a:not(.btn) {
  color: #fff;
  letter-spacing: .02em;
  font-family: inter, sans-serif;
  font-size: 1.25rem;
}

.act .action a:not(.btn):hover {
  text-decoration: underline;
}

.act iframe {
  aspect-ratio: 560 / 315;
  height: auto;
  width: 100%;
}

/*# sourceMappingURL=index.85ff5c82.css.map */
